<template>
  <div>
    <div class="d-flex align-items-center">
      <div class="flex-grow-1 me-3">{{ item.value }}{{ unit }}</div>
      <div class="w-50 me-3">
        <div class="small">{{ item.createdAt | utcDateReadable }}</div>
        <div class="smaller text-muted">{{ item.createdAt | utcDateRelative }}</div>
      </div>
      <IconButton
        @click="showConfirmModal = true"
        :icon="'trash-alt'"
        :isLoading="isDeleting"
        :disabled="isDeleting"
        class="btn btn-sm btn-danger"
      ></IconButton>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteItem"
          @close="showConfirmModal = false"
          v-model="showConfirmModal"
          v-if="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>
  </div>
</template>

<script>
import { httpDelete } from '@/core/http';

export default {
  name: 'MetricHistoryItem',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  methods: {
    async deleteItem() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const { metricId, metricHistoryId, userId } = this.item;
        await httpDelete('/metric/history', {
          metricId,
          metricHistoryId,
          userId,
        });
        this.$emit('delete', metricHistoryId);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
  },
  data() {
    return {
      showConfirmModal: false,
      isDeleting: false,
    };
  },
};
</script>
